import React, { useState } from 'react';
import NcashCompanyList from './NcashCompanyList';
import MisuList from './MisuList';
import { Button } from 'antd';
import DepositDialog from '../../components/dialog/DepositDialog';
import AgecnyDeliveryList from './AgecnyDeliveryList';

const NcashListLayout = () => {
  const [pageContent, setPageContent] = useState(0);
  const [depositDialogOpen, setDepositDialogOpen] = useState(false);
  const [ncashListTrigger, setNcashListTrigger] = useState(false);

  const pageContentCode = [
    {
      value: 0,
      menu: '예치금내역',
    },
    {
      value: 1,
      menu: '미정산내역',
    },
    // {
    //   value: 2,
    //   menu: '배달내역',
    // },
  ];

  return (
    <>
      {depositDialogOpen && (
        <DepositDialog
          refresh={() => console.log('refresh')}
          getList={() => setNcashListTrigger(!ncashListTrigger)}
          close={() => setDepositDialogOpen(false)}
        />
      )}

      <div
        style={{
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'space-around',
          marginBottom: 20,
        }}>
        {pageContentCode.map((code) => {
          return (
            <>
              <div
                style={{
                  flex: 1,
                  borderBottomColor: 'grey',
                  borderBottomStyle: 'solid',
                  borderBottomWidth: 1,
                }}
              />
              <div
                style={{
                  flex: 5,
                  padding: 10,
                  borderColor: 'grey',
                  borderStyle: 'solid',
                  borderWidth: 1,
                  cursor: 'pointer',
                  fontWeight: 'bold',
                  textAlign: 'center',
                  borderBottom: pageContent === code.value ? 'none' : 'grey solid 1px',
                }}
                key={code.value}
                onClick={() => setPageContent(code.value)}>
                {code.menu}
              </div>
            </>
          );
        })}
        <div
          style={{
            flex: 1,
            borderBottomColor: 'grey',
            borderBottomStyle: 'solid',
            borderBottomWidth: 1,
          }}
        />
        <div
          style={{
            borderBottomColor: 'grey',
            borderBottomStyle: 'solid',
            borderBottomWidth: 1,
          }}>
          <Button onClick={() => setDepositDialogOpen(true)}>예치금 이체</Button>
        </div>
        <div
          style={{
            flex: 100,
            borderBottomColor: 'grey',
            borderBottomStyle: 'solid',
            borderBottomWidth: 1,
          }}
        />
      </div>
      {pageContent === 0 && <NcashCompanyList ncashListTrigger={ncashListTrigger} />}
      {pageContent === 1 && <MisuList />}
      {pageContent === 2 && <AgecnyDeliveryList />}
    </>
  );
};

export default NcashListLayout;
